import * as shared from '../sharedTypes.js'
import { userColor } from '../usercolor.js'

shared.awareness.setLocalStateField('user', {
  name: localStorage.getItem('username') || 'Anonymous',
  color: userColor.color,
  colorLight: userColor.light
})

document.documentElement.style.setProperty('--user-color', userColor.color)
document.documentElement.style.setProperty('--user-color-light', userColor.light)
